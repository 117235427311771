@import './components/Logo/Logo';

// //add body wrapper class here
//trans menu
.homepage-wrapper {
  nav {
    background-color: transparent;
  }
}

nav {

  transition: all 0.4s ease-in-out;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 9999;

  &.nav-up {
    margin-top: -9.5em !important;
  }

  &.menu-scroll {
    background-color: rgba($primary-light, .98);

    .menu__container {

      span {
        color: $primary-sharp-dark;
      }

      a {
        color: rgba($primary-sharp-dark, 0.8);
        text-decoration: none;

        &:focus,
        &:hover {}

        &:focus {
          outline: thin dotted;
          outline: 5px auto -webkit-focus-ring-color;
          outline-offset: -2px;
        }
      }
      svg {
        fill: $primary-sharp-dark;
        &:hover {
          fill:$link-color-light;
          
        }
      }

      .ham-hamburger {
        background-color: $primary-sharp-dark;

        &:before,
        &:after {
          background-color: $primary-sharp-dark;
        }
      }

      .ham {
        ul li a {
          &:hover {
            border-bottom: 2px solid $primary-sharp-dark;
          }
        }
      }
    }
  }
}

.menu__container {
  height: 85px;
  position: relative;
  padding-top: 10px;
  padding-left:3rem;
  padding-right: 3rem;


  span {
    color: $primary-sharp-dark;
  }

  a {
    text-decoration: none;

    &:focus {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }



  .list-inline {
    margin-top: .9rem;
    padding-left: 0;
    list-style: none;
  }

  .list-inline-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  .ham-hamburger__wrapper {
    margin-top: 10px;
    // margin-right: $site-gutter;
    position: relative;
    cursor: pointer;
    z-index: 2;
    display: block;
    width: 50px;
    float: right;

    &:hover .ham-hamburger {
      width: 30px;

      &:before {
        width: 30px;
      }
    }
  }

  @media all and (max-width: 47.875em) {
    .ham-hamburger {
      width: 24px !important;
      background: $primary-sharp-dark;
    }
  }

  .ham.is-active .ham-hamburger {
    background: none;

    &:before,
    &:after {
      top: 0;
      width: 24px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:after {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &:hover {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  .ham-hamburger {
    float: right;
    position: relative;
    z-index: 1000;
    width: 22px;
    height: 2px;
    margin: 15px 0;
    background: $primary-sharp-dark;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -moz-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      background: $primary-sharp-dark;
      position: absolute;
      -moz-border-radius: 4px;
      border-radius: 4px;
      -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -o-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -moz-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &:before {
      top: -10px;
      right: 0;
      width: 15px;
    }

    &:after {
      top: 10px;
      width: 30px;
      right: 0;
    }
  }

  .ham {
    margin-top: 10px;
    display: inline-block;
    width: 24px;
    height: 23px;
    z-index: 10;

    .is-active li {
      position: relative;
      z-index: 10;
    }

    position: relative;
    width: 100%;

    ul {
      position: absolute;
      right: 5rem;
      width: 100%;

      @media (max-width: $large) {
        display: none;
      }
    }

    ul li {
      margin-top: 1px;
      float: right;
      font-size: 0.95rem;
      font-family: $sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-left: 2rem;

      &:first-child a {
        -webkit-transition-delay: 0.10s;
        -moz-transition-delay: 0.10s;
        -o-transition-delay: 0.10s;
        transition-delay: 0.10s;
      }

      &:nth-of-type(2) a {
        -webkit-transition-delay: 0.20s;
        -moz-transition-delay: 0.20s;
        -o-transition-delay: 0.20s;
        transition-delay: 0.20s;
      }

      &:nth-of-type(3) a {
        -webkit-transition-delay: 0.30s;
        -moz-transition-delay: 0.30s;
        -o-transition-delay: 0.30s;
        transition-delay: 0.30s;
      }

      &:nth-of-type(4) a {
        -webkit-transition-delay: 0.40s;
        -moz-transition-delay: 0.40s;
        -o-transition-delay: 0.40s;
        transition-delay: 0.40s;
      }

      &:nth-of-type(5) a {
        -webkit-transition-delay: 0.50s;
        -moz-transition-delay: 0.50s;
        -o-transition-delay: 0.50s;
        transition-delay: 0.50s;
      }

      &:nth-of-type(6) a {
        -webkit-transition-delay: 0.60s;
        -moz-transition-delay: 0.60s;
        -o-transition-delay: 0.60s;
        transition-delay: 0.60s;
      }
    }

    ul li .current-page {
      border-bottom: 2px solid $primary-sharp-dark;
    }

    ul li a {
      &:hover {
        border-bottom: 2px solid $primary-sharp-dark;
      }

      user-select: none;
      line-height: 25px;
      color: $primary-sharp-dark;
      display: block;
      opacity: 0;
      -webkit-transition-property: opacity transform;
      -o-transition-property: opacity transform;
      -moz-transition-property: opacity transform;
      transition-property: opacity transform;
      -webkit-transition-duration: .3s !important;
      -moz-transition-duration: .3s !important;
      -o-transition-duration: .3s !important;
      transition-duration: .3s !important;
      -webkit-transition-timing-function: ease !important;
      -moz-transition-timing-function: ease !important;
      -o-transition-timing-function: ease !important;
      transition-timing-function: ease !important;
    }

    &.is-active li>a {
      opacity: 1;
      -webkit-transform: translateX(-10px);
      -moz-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
      -o-transform: translateX(-10px);
      transform: translateX(-10px);
    }

    &.is-active:before {
      opacity: 0;
      -webkit-transform: translateX(-10px);
      -moz-transform: translateX(-10px);
      -ms-transform: translateX(-10px);
      -o-transform: translateX(-10px);
      transform: translateX(-10px);

      @media all and (max-width: 47.875em) {
        opacity: 1;
      }
    }

    &.is-open:before {
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;

      @media all and (max-width: 47.875em) {
        -webkit-transition-delay: 0;
        -moz-transition-delay: 0;
        -o-transition-delay: 0;
        transition-delay: 0;
      }
    }

    &.is-open li {
      &:first-child a {
        -webkit-transition-delay: 0.15s;
        -moz-transition-delay: 0.15s;
        -o-transition-delay: 0.15s;
        transition-delay: 0.15s;
      }

      &:nth-of-type(2) a {
        -webkit-transition-delay: 0.3s;
        -moz-transition-delay: 0.3s;
        -o-transition-delay: 0.3s;
        transition-delay: 0.3s;
      }

      &:nth-of-type(3) a {
        -webkit-transition-delay: 0.45s;
        -moz-transition-delay: 0.45s;
        -o-transition-delay: 0.45s;
        transition-delay: 0.45s;
      }

      &:nth-of-type(4) a {
        -webkit-transition-delay: 0.6s;
        -moz-transition-delay: 0.6s;
        -o-transition-delay: 0.6s;
        transition-delay: 0.6s;
      }

      &:nth-of-type(5) a {
        -webkit-transition-delay: 0.75s;
        -moz-transition-delay: 0.75s;
        -o-transition-delay: 0.75s;
        transition-delay: 0.75s;
      }

      &:nth-of-type(6) a {
        -webkit-transition-delay: 0.9s;
        -moz-transition-delay: 0.9s;
        -o-transition-delay: 0.9s;
        transition-delay: 0.9s;
      }
    }
  }
  .light & {
      .ham-hamburger {
        background-color: $white;
        &:before, &:after {
          background-color: $white;
        }
      }
      svg {
        fill: $white;
        &:hover {
          fill:rgba($white, 0.7);
          
        }
      }
      span {
        color:$white;
      }
      .ham ul li {
      .current-page {
        border-color: $white;
      }
      a:hover{
        border-color: $white;
      }
    }
    
    
  }
}

.scroll-to-work {
  cursor: pointer;
}

//mobile menu
.mobile-menu {
  height: 100%;
  width: 100%;
  background-color: $primary-light;
  position: fixed;
  z-index: 999;
  // background-image: url('/assets/images/menu-background.jpg');
  background-image: url('../../assets/menu-background.jpg');
  background-size: cover;
  transition: opacity ease-in-out 1.5s;

  .overlay {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($primary-light, .9);
  }

  .close-mobile-menu {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transition: opacity ease-in-out 1.5s;

    .close {
      cursor: pointer;
      position: absolute;
      right: 0px;
      left: 0px;
      margin: auto;
      top: 40px;
      width: 22px;
      height: 22px;

      &:hover {

        &:before,
        &:after {
          background-color: $link-color-highlight;
        }
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &:before,
      &:after {
        transition: $transition;
        position: absolute;
        left: 15px;
        content: ' ';
        height: 34px;
        border-radius: 1.5px;
        width: 6px;
        background-color: $primary-sharp-dark;
      }
    }
  }

  .fade-in {
    transition: opacity ease-in-out 2.5s;
    opacity: 1 !important;
  }

  .mobile-menu-items {
    z-index: 2;
    position: relative;
    transition: opacity ease-in-out 2.5s;
    padding-top: 100px;
    opacity: 0;

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
      padding-top: 175px;
    }

    a.current-page {
      color: $link-color-highlight !important;
    }

    a {
      cursor: pointer;
      transition: $transition;
      text-decoration: none;
      line-height: 1.5em;
      font-weight: 600;
      font-size: 50px;
      text-align: center;
      display: block;
      font-family: $sans-serif;
      color: $primary-sharp-dark !important;

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
        font-size: 80px;
        line-height: 1.8em;
      }

      @media(max-width: 325px) {
        font-size: 45px;
      }

      @media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
        font-size: 35px;
      }

      @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : landscape) {
        font-size: 40px;
      }

      &:hover {
        color: $link-color-highlight !important;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    display: none;

  }
}