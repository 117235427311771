@use 'sass:map';
@use '../../variables' as page-blocks;
@use './variables' as global-block-hero;

.GlobalHeroBlock {
  background-color: $alt-light;
  img {
    width: 60%;
    height: auto;
  }
  h1 {
    margin-top: 70px;
    font-size: 3.998rem;
    margin-bottom: 4rem;
  }
  p {
    width: 100%;
    line-height: 1.7em;
    font-size: 26px;
    font-weight: 300;
  }
  .GlobalHeroBlock__image-wrapper {
    text-align: center;

  }
  .GlobalHeroBlock__text-wrapper {

  }
  .row {
    width: 85%;
    margin: 50px auto;
    
  }

}