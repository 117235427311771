@use './variables'as footer;

.Footer__wrapper {
  display: flex;
  flex-direction: column;
  gap: footer.$gap;
  background-color: #ffffff;
  z-index: 1;
}
.page-contact,.page-expertise {
  .Footer__conactCallout {
    display: none;
  }

}

.Footer__conactCallout {
  padding-top: 5em;
  padding-bottom: 5em;
  width: 100%;
  margin: 0 auto;

  h3 {
    width: 40%;
    margin: 0 auto 50px;
    font-family: "Source Sans Pro", sans-serif;
    text-align: center;
    font-size: 55px;
    color: #343434;
    @include media-breakpoint-down(md) {
      width: 65%;
      font-size: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
      width: 85%;
    }

  }

  p {
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      width: 65%;
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(sm) {
      width: 85%;
    }
  }
  .LongButton {
    margin: 0 auto;
    padding-left: 0;
  }

}

.Footer {
  padding-right: footer.$gap;
  padding-bottom: footer.$gap;
  padding-left: footer.$gap;
  padding-top: footer.$gap;

  text-align: center;

  a {
    color: $color-primary;
  }
}

.Footer__logo__wrapper {
  // for pixel-perfect spacing
  margin-bottom: calc(footer.$gap - footer.$one-quarter-of-line-height);

  display: inline-block;
}

.Footer__logo {
  display: inline-block;
  width: auto;
  height: 3.5rem;

  color: $color-black;
  transition: width ease-in-out .4s;
  display: block;
  height: auto;
  width: 140px;
  margin: auto;
  stroke: #343434;
  stroke-width: 7;
  fill: none;

  .st2 {
    fill: #343434;
  }
}

.Footer__columns {
  padding-bottom: 2rem;
}

.Footer__accreditation {
  max-width: 110px;
  width: 100%;
  gap: 1em;
}

.Footer__pageLinks {
  // for pixel-perfect spacing
  margin-top: calc(footer.$gap - footer.$one-quarter-of-line-height);

  line-height: footer.$line-height;

  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  gap: 1em;
}

.Footer__pageLink {
  text-decoration: underline;
}
