@use 'family.scss/source/src/family' as family;
@use './variables' as tile;

.Tile__wrapper {
  // intentionally blank
}
.Tile__link {
  text-decoration: none;
  color: initial;
  transition: .4s ease;
}

.Tile {
  height: 100%;

  @include media-breakpoint-down(lg) {
    padding: tile.$padding;
  }

  @include media-breakpoint-up(lg) {
    padding: tile.$padding-lg;
  }


  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.5rem;
}

.Tile__header {
  $this: &;

  margin: 0; // override default <h*>

  @at-root .Tile__wrapper {

    @include family.at-most(3) {

      #{$this} {

        @include media-breakpoint-down(xxl) {
          font-size: 1.25rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 1.5rem;
        }
      }
    }

    @include family.at-least(4) {

      #{$this} {

        @include media-breakpoint-down(xxl) {
          font-size: 1.5rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 1.875rem;
        }
      }
    }
  }

  line-height: 1.2;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  font-weight: 700;

}

.Tile__body {
  line-height: 2;

  @at-root p#{&} {
    margin-bottom: 0; // override default <p>
  }

  > p {

    &:last-child {
      margin-bottom: 0; // override default <p>
    }
  }
}

.Tile__image__wrapper {
  order: -1;

  @include media-breakpoint-down(lg) {
    width: calc(100% + (tile.$padding * 2));
    margin-top: (tile.$padding * -1);
  }

  @include media-breakpoint-up(lg) {
    width: calc(100% + (tile.$padding-lg * 2));
    margin-top: (tile.$padding-lg * -1);
  }
}

.Tile__image {
  display: block;
  width: auto;
  height: 55px;
}
.Tile__icon {
  height: 32px;
  width: auto;
  margin-top: 1.5rem;
}
.Tile__link {
  svg {
  transition: ease all .2s;
  display: inline;
  height: 11.5px;
  margin-left: 15px;
  }
  &:hover {
    color: $color-black;
    svg {
      transform: translateX(10px);
    }
  }
}
