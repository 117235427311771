
  
  .DeviceStudyBlock {
    .DeviceStudyBlock__wrapper {
      background-color: $alt-light;
      .DeviceStudyBlock__content{
        padding-top: 18vh;
        width: 100%;

      }
      .scroll-wrapper {
        display: block;
        @media screen and (max-width: 800px) {
          display: none;
        }
        height: 300vh;
        .device-container {
          height: 500px;
        }
        .text-blurb {
          .text-wrapper {
            padding-top: 3em;
            width: 100%;
            margin: 0 auto;
            .text-block {
              display: block;
              @media screen and (max-width: 800px) {
                width: 100%;
                margin-left: 0;
                min-height: auto;
                display: block;
  
              }
              .text-inner {
                z-index: 20;
                position: relative;
                // margin-top: 60px;
                h2 {
                  width: 75%;
                  margin: 0;
                }
                p {
                  line-height: 1.618em;
                  font-size: 18px;
                  width: 80%;
                  @media screen and (max-width: 800px) {
                    font-size: 16px;
                  }
                  font-weight: 200;
                }
              }
            }
          }
        }
        @media screen and (max-width: 800px) {
          padding: 0;
          margin: 0;
          .text-blurb, .device-container {
            width: 100%;
          }
        }
        &.scroller {
          position: fixed;
          top: 0;
          // padding-top: 0;
          @media screen and (max-width: 800px) {
            position: relative;
          }
        }
      }
      .mobile-text-wrapper {
        padding-top: 3em;
        width: 100%;
        margin: 0 auto;
        display: none;
         @media screen and (max-width: 800px) {
          display: block;
         }
         .image-container {
          text-align: center;
          min-height: 300px;
          .md-device-desktop {
            padding: 20px;
            .md-border-element {
              height: 40px;
            }
            .md-base-element{
             -webkit-transform: translateY(40px) translateX(-50%) translateY(0);
             -moz-transform: translateY(40px) translateX(-50%) translateY(0);
             -ms-transform: translateY(40px) translateX(-50%) translateY(0);
             transform: translateY(40px) translateX(-50%) translateY(0);
             border-bottom: 40px solid #ddd;
             &:after {
              transform: translateY(40px) translateX(-50%);
             }
           }
         }
         .md-device-laptop {
            padding: 20px;
            .md-border-element {
              height: 20px;
            }
            .md-base-element{
             -webkit-transform: translateY(40px) translateX(-50%) translateY(0);
             -moz-transform: translateY(40px) translateX(-50%) translateY(0);
             -ms-transform: translateY(40px) translateX(-50%) translateY(0);
             transform: translateY(40px) translateX(-50%) translateY(0);
             border-bottom: 40px solid #ddd;
             &:after {
              transform: translateY(40px) translateX(-50%);
             }
           }
         }
       }
       .text-block-mobile {
        margin: 0 auto;
        width: 90%;
        padding: 3rem 0;
          h2 {
            margin: 0;
            text-align: center;
          }
          p {
            text-align: center;
            line-height: 1.618em;
            font-size: 18px;
            @media screen and (max-width: 800px) {
              font-size: 16px;
            }
            font-weight: 200;
          }
  
        }
      }
    }
  
  /* Slider style */
  .md-slider {
    width: 100%;
    height: 700px;
    padding-top: 40px;
    // position: absolute;
    // top: 140vh;
    margin: 10px 0;
    &.scroller {
      position: fixed;
      top: 0;
    }
  }
  
  /* General style for all devices */
  .md-device-wrapper {
    position: relative;
    text-align: center;
    width: 760px;
    // margin-left: 45%;
  }
  
  .md-device {
    position: relative;
    display: inline-block;
    border-radius: 15px;
    background-color: #333;
    & > .dev-wrap,
    & > .dev-wrap div {
      display: block;
      outline: none;
    }
    & > .dev-wrap {
      position: relative;
    }
    & > .dev-wrap div {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
  
      &.md-fadeout {
        opacity: 0;
      }
    }
    /* reset box sizing for this demo */
    /* we want the paddings to be added to our width/height */
    &,
    & div,
    &:before,
    &:after {
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }
  }
  
  
  /* Common pseudo elements' properties */
  /* md-device:before -- camera / top element */
  /* md-device:after -- home button */
  /* md-base-element:after -- socket of base */
  /* md-border-element:after -- grey inset opending macbook */
  .md-device:before,
  .md-device:after,
  .md-base-element:after,
  .md-border-element:after {
    position: absolute;
    content: '';
    z-index: 10;
    left: 50%;
    top: 0;
  }
  
  .md-device {
    &:before,
    &:after {
      background: #000;
    }
    &:before {
      -webkit-transform: translateY(300%) translateX(-50%);
      -moz-transform: translateY(300%) translateX(-50%);
      -ms-transform: translateY(300%) translateX(-50%);
      transform: translateY(300%) translateX(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
    & > div {
      position: absolute;
    }
    & button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      z-index: 10;
      border: none;
      color: #fff;
      background: #101010;
      text-align: center;
      cursor: pointer;
      margin: -25px 0 0 -25px;
      opacity: 0;
      outline: none;
  
      &:before {
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-size: 30px;
        line-height: 50px;
        -webkit-font-smoothing: antialiased;
        content: "\e000";
      }
    }
    &.md-rotated button {
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      transform: scaleX(-1);
    }
    /* md-device:after -- home button (device 3 and 4) */
    &:after {
      width: 24px;
      height: 24px;
      top: 100%;
      border-radius: 50%;
      -webkit-transform-origin: 50% 100%;
      -moz-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      -webkit-transform: translateY(-200%) translateX(-50%) scale(0);
      -moz-transform: translateY(-200%) translateX(-50%) scale(0);
      -ms-transform: translateY(-200%) translateX(-50%) scale(0);
      transform: translateY(-200%) translateX(-50%) scale(0);
    }
  }
  /* button for rotating a device */
  
  .md-device:hover button,
  .touch .md-device button {
    opacity: 1;
  }
  
  /* Let's predefine some properties so that transitions happen smoothly */
  .md-border-element,
  .md-base-element,
  .md-border-element:after {
    opacity: 0;
  }
  
  /* md-border-element -- border bottom iMac, keyboard/base macbook */
  .md-border-element {
    height: 60px;
    border-radius: 0 0 15px 15px;
    top: 100%;
    width: 100%;
    background: #ddd;
    left: 50%;
    /* md-border-element:after; -- grey inset opending macbook */
    &:after {
      background: #aaa;
      width: 100px;
      border-radius: 0 0 6px 6px;
      height: 10px;
      opacity: 0;
    }
    &,
  &:after {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  }
  
  
  /* md-base-element -- base imac */
  .md-base-element {
    top: 100%;
    left: 50%;
    width: 160px;
    height: 0;
    background: transparent;
    border-bottom: 60px solid #ddd;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    -webkit-transform: translateY(60px) translateX(-50%) translateY(-75%);
    -moz-transform: translateY(60px) translateX(-50%) translateY(-75%);
    -ms-transform: translateY(60px) translateX(-50%) translateY(-75%);
    transform: translateY(60px) translateX(-50%) translateY(-75%);
    z-index: -1;
    /* md-base-element:after -- socket of base imac */
    &:after {
      background: #ddd;
      border-radius: 30px 30px 10px 10px;
      width: 260px;
      height: 12px;
      -webkit-transform: translateY(60px) translateX(-50%);
      -moz-transform: translateY(60px) translateX(-50%);
      -ms-transform: translateY(60px) translateX(-50%);
      transform: translateY(60px) translateX(-50%);
    }
  }
  
  /* Device 0 (large monitor) */
  .md-device-desktop {
    padding: 30px;
    border-radius: 15px 15px 0 0;
    & > .dev-wrap {
      width: 700px;
      height: 400px;
    }
    & .md-border-element {
      opacity: 1;
    }
    & .md-base-element {
      opacity: 1;
      -webkit-transform: translateY(60px) translateX(-50%) translateY(0);
      -moz-transform: translateY(60px) translateX(-50%) translateY(0);
      -ms-transform: translateY(60px) translateX(-50%) translateY(0);
      transform: translateY(60px) translateX(-50%) translateY(0);
    }
    @media screen and (max-width: 800px) {
      & > .dev-wrap {
        width: 350px;
        height: 200px;
      }
    }
  }
  
  /* Device 1 (laptop) */
  .md-device-laptop {
    padding: 15px;
    border-radius: 15px 15px 0 0;
    & > .dev-wrap {
      width: 624px;
      height: 390px;
      border-radius: 10px 10px; // Add this line to give the image rounded edges
      overflow: hidden; // Add this line to clip the image to the rounded edges
    }
    & .md-border-element {
      opacity: 1;
      width: 125%;
      height: 20px;
      background: #ddd;
      border-radius: 0 0 20px 20px;
    }
    & .md-border-element:after {
      opacity: 1;
    }
    &::before{
      display: block;
      width: 60px;
      height: 15px;
      background: #333;
      border-radius: 2px;
      top: 10px; // Adjust the position to place it at the top of the screen
      // left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);

    }
    @media screen and (max-width: 800px) {
      & > .dev-wrap {
        width: 300px;
        height: 190px;
      }
    }
  }
  /* Device 2 (iPad) */
  .md-device-ipad {
    padding: 12px 12px; // Adjust padding for a slimmer appearance
    border-radius: 25px; // Add border radius for a more modern look
    &.md-rotated {
      -webkit-transform: translateY(40px) rotate(90deg);
      -moz-transform: translateY(40px) rotate(90deg);
      -ms-transform: translateY(40px) rotate(90deg);
      transform: translateY(40px) rotate(90deg);
    }
    & > .dev-wrap {
      width: 320px;
      height: 425px;
      border-radius: 15px; // Add this line to give the image rounded edges
      overflow: hidden; // Add this line to clip the image to the rounded edges
      
    }
    &:before {
      // -webkit-transform: translateY(600%) translateX(-50%);
      // -moz-transform: translateY(600%) translateX(-50%);
      // -ms-transform: translateY(600%) translateX(-50%);
      // transform: translateY(600%) translateX(-50%);
      width:0;
      height: 0;
      top: 0;
    }

    &:after {
      -webkit-transform: translateY(-175%) translateX(-50%) scale(1);
      -moz-transform: translateY(-175%) translateX(-50%) scale(1);
      -ms-transform: translateY(-175%) translateX(-50%) scale(1);
      transform: translateY(-175%) translateX(-50%) scale(1);
      width: 0; // Adjust the width of the home button
      height: 0; // Adjust the height of the home button
    }
  }
  
  /* Device 3 (iPhone) */
  .md-device-iphone {
    padding: 6px 6px 6px 6px;
    border-radius: 15px; // Add border radius for a more modern look


    &.md-rotated {
      -webkit-transform: translateY(50px) rotate(90deg);
      -moz-transform: translateY(50px) rotate(90deg);
      -ms-transform: translateY(50px) rotate(90deg);
      transform: translateY(50px) rotate(90deg);
    }
    & > .dev-wrap {
      width: 200px;
      height: 433px;
      border-radius: 10px; // Add this line to give the image rounded edges
      overflow: hidden; // Add this line to clip the image to the rounded edges


    }
    &:before {
      display: block;
      width: 60px;
      height: 15px;
      background: #333;
      border-radius: 2px;
      top: 0; // Adjust the position to place it at the top of the screen
      // left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    &:after {
      width: 0;
      height: 0;
      -webkit-transform: translateY(-200%) translateX(-50%) scale(1);
      -moz-transform: translateY(-200%) translateX(-50%) scale(1);
      transform: translateY(-200%) translateX(-50%) scale(1);
    }
  }
  /* Device 4 (Artboard) */
  .md-device-artboard {
    padding: 55px 40px;
    background-color: transparent;
    border: $border-light 2px solid;
    &.md-rotated {
      -webkit-transform: translateY(40px) rotate(90deg);
      -moz-transform: translateY(40px) rotate(90deg);
      -ms-transform: translateY(40px) rotate(90deg);
      transform: translateY(40px) rotate(90deg);
    }
    & > .dev-wrap {
      width: 280px;
      height: 375px;
      z-index: 2;
    }
    &:before {
      width: 278px;
      background: transparent;
      border-right: 1px solid $border-light;
      border-left: 1px solid $border-light;
      border-radius: 0;
      height: 50px;
      top: -145px;
  
    }
    button {
      position: absolute;
      top: 55px;
      left: 5px;
      width: 350px;
      height: 375px;
      border-radius: 0%;
      z-index: 0;
      opacity: 1;
      border-top: 1px solid $border-light;
      border-bottom: 1px solid $border-light;
      background: transparent;
      text-align: center;
      cursor: pointer;
      margin: 0;
      outline: none;
      display: block !important;
      &:before {
        content: "";
      }
    }
     &:after {
      width: 278px;
      background: transparent;
      border-right: 1px solid $border-light;
      border-left: 1px solid $border-light;
      border-radius: 0;
      height: 50px;
      z-index: 10;
      left: 40px;
      top: 430px;
      transform: translate(0,0);
      transform: scale(100%);
  
       }
  }
  
  /* Transitions */
  .md-device,
  .md-device:before,
  .md-border-element,
  .md-device > .dev-wrap {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
  }
  
  .md-base-element,
  .md-device:after,
  .md-border-element:after {
    -webkit-transition: -webkit-transform 0.5s, opacity 0.3s;
    -moz-transition: -moz-transform 0.5s, opacity 0.3s;
    transition: transform 0.5s, opacity 0.3s;
  }
  
  .md-device,
  .md-device:before,
  .md-border-element,
  .md-device > .dev-wrap,
  .md-base-element,
  .md-device:after,
  .md-border-element:after {
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
  }
  
  .md-device > .dev-wrap div {
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear;
  }
  
  .md-device button {
    -webkit-transition: -webkit-transform 0.5s, opacity 0.3s;
    -moz-transition: -moz-transform 0.5s, opacity 0.3s;
    transition: transform 0.5s, opacity 0.3s;
  }
  
  @media screen and (max-width: 800px) {
    .project-wrapper .md-device {
  
    }
    .md-slider {
      height: 400px;
    }
    .md-device {
     &.md-device-desktop, &.md-device-laptop,&.md-device-ipad, &.md-device-iphone, &.md-device-artboard {
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
        text-align: center;
      }
      &.md-device-artboard {
        button{
          display: block !important;
      }
      }
  
    }
    .md-device-wrapper {
      width: 100%;
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }
  @media screen and (min-width: 800px) {
    .md-slider {
      height: 500px;
    }
    .md-device-wrapper {
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.5);
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      transform-origin: 0 0;
      left: 5%;
      .md-device {
        &.md-device-ipad, &.md-device-iphone, &.md-device-artboard {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
           transform-origin: 0 0 ;
          }
        }
    }
  }
  @media screen and (min-width: $xlarge) {
    .md-slider {
      height: 500px;
    }
    .md-device-wrapper {
      -webkit-transform: scale(.8);
      -moz-transform: scale(.8);
      -ms-transform: scale(.8);
      transform: scale(.8);
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      transform-origin: 0 0;
      left: 5%;
      .md-device {
        &.md-device-ipad {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
           transform-origin: 0 0 ;
          }
        }
    }
  }
  
  }
  