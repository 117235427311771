.LongButton {
  $a-tags: 'a, a:hover, a:focus, a:active, a:visited';

  #{$a-tags} {
    color: $primary-light;
    text-decoration: none;
    font-size: 1em;
  }
  
    margin: 0;
    padding-left: 2.5rem;
    position: relative;
    width: 320px;
    max-height: 60px;
    cursor: default;
  
    @media (max-width: $medium) {
      padding: 0;
      margin: 0 auto;
    }
  
    &:hover {
      cursor: pointer;
  
      .rect-shape {
        // stroke-width: 2px;
        stroke-dashoffset: 0;
        stroke-dasharray: 760;
      }
    }
  
    svg {
      text {
        fill: $primary-dark;
      }
  
      rect {
        stroke: $primary-dark;
        border-bottom: 5px solid $primary-dark;
        fill: transparent;
        stroke-dasharray: 140 540;
        stroke-dashoffset: -474;
        stroke-width: 6px;
        transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
      }
    }
  
    .text {
      z-index: 10;
      font-family: $sans-serif !important;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      letter-spacing: 4px;
      color: $primary-dark;
      top: -48px;
      position: relative;
      text-align: center;
      fill: $primary-dark;
    }
    &.white {
      .text {
        color: $white;
        fill: $white;
      }
      svg {
        text {
          fill: $white;
        }
        rect {
          stroke: $white;
          border-bottom: 5px solid $white;
        }
      }
    }
    
  

}