@use './variables' as input;

.Input {
  // override default <input>
  outline: none;

  display: block;
  width: 100%;
  height: input.$height;
  padding: input.$padding;

  color: $color-black;
  line-height: 1;

  border-radius: 3px;
  border: 1px solid $color-gray-200;
  background-color: $body-bg;

  &:focus {
    border-color: $color-primary;
    background-color: $color-white;
  }

  &.error {
    border-color: red;
    background-color: $color-white;
  }
}

.Input[type=checkbox] {
  width: 42px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  accent-color: $color-primary;
  align-self: flex-start;
}
