@use 'sass:map';
@use '../../variables' as page-blocks;
@use './variables' as global-block-hero;

.WorkHeroBlock {


  display: grid;
  width: 100%;
  min-height: 50vh;
  max-height: 700px;
  background-color: rgb(248, 248, 248);
  position: relative;
  background-size: cover;
  background-attachment: fixed;
  transition: opacity 2s ease 0s;
}
.WorkHeroBlock__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); // adjust the 0.5 value to set the desired transparency
  z-index: 1;
}

.WorkHeroBlock__header__wrapper,
.WorkHeroBlock__backgroundImage {
  grid-area: 1 / 1 / 2 / 2;
  img {
    min-height: 50vh;
    max-height: 700px;
  }
}

.WorkHeroBlock__header__wrapper {
  position: relative;
  z-index: map.get(global-block-hero.$layers, 'header') + 1;
  min-height: 540px;
  padding: var(--bs-gutter-x, 0.75rem);

  @include media-breakpoint-down(sm) {
    padding-top: global-block-hero.$offset-xs;
  }

  @include media-breakpoint-up(sm) {
    padding-top: global-block-hero.$offset-xs;
  }

  &.black {
    color: $color-black;
  }

  &.white {
    color: $color-white;
  }

  text-align: left;

  // display: flex;
  // align-items: center;
  // justify-content: center;
  h1,p {
    color:$color-white;
  }
  h1{
    // color: $body-bg;
    font-weight: 800;
    margin-bottom: 1rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.3); // adjust the values as needed

  }
  p {
    // color: $body-bg;
    font-weight: 600;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.3); // adjust the values as needed
    @include media-breakpoint-up(md) {
      font-size: 22px;
    }

  }
  max-width: 50%;
  margin-top: 3rem;
  padding-left: 3rem;
  @include media-breakpoint-down(xl) {
    max-width: 75%;
  }
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

.WorkHeroBlock__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-only(sm) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.75rem;
  }

  line-height: 1;
}
