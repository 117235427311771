@use 'sass:map';
@use '../../variables' as page-blocks;
@use './variables' as global-block-hero;

.AboutHeroBlock {

  &:first-child {

    @include media-breakpoint-down(sm) {
      margin-top: ((page-blocks.$gap-xs * -1) - global-block-hero.$offset-xs);
    }

  //   @include media-breakpoint-up(sm) {
  //     margin-top: ((page-blocks.$gap * -1) - global-block-hero.$offset);
  //   }
  }

  display: grid;
  // margin-top: 85px;
  width: 100%;
  min-height: 50vh;
  background-color: rgb(248, 248, 248);
  position: relative;
  background-size: cover;
  background-attachment: fixed;
  transition: opacity 2s ease 0s;
}

.AboutHeroBlock__header__wrapper,
.AboutHeroBlock__backgroundImage {
  grid-area: 1 / 1 / 2 / 2;
  position: relative;
  height: 100%;
  clip-path: inset(0);
  img {
    // opacity: 0;
    transition: opacity ease 2s;
    width: 100%;
    background-color: #f8f8f8;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    object-fit: cover;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(md) {
      object-position: 68%;
    }
  }
}

.AboutHeroBlock__header__wrapper {
  position: relative;
  z-index: map.get(global-block-hero.$layers, 'header');
  min-height: 540px;
  padding: var(--bs-gutter-x, 0.75rem);

  @include media-breakpoint-down(sm) {
    padding-top: global-block-hero.$offset-xs;
  }

  @include media-breakpoint-up(sm) {
    padding-top: global-block-hero.$offset;
  }

  &.black {
    color: $color-black;
  }

  &.white {
    color: $color-white;
  }

  text-align: left;

  // display: flex;
  // align-items: center;
  // justify-content: center;
  h1,p {
    color:$color-white;
  }
  h1{
    font-weight: 800;
    margin-bottom: 15px;
  }
  p {
    color: $body-bg;
    line-height: 35px;
    width: 90%;
    font-size: 22px;
    font-weight: 500;
  }
  max-width: 50%;
  margin-top: 12rem;
  padding-left: 3rem;
  @include media-breakpoint-down(xl) {
    max-width: 75%;
  }
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

.AboutHeroBlock__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-only(sm) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.75rem;
  }

  line-height: 1;
}
