@keyframes wave-animation {
  0% {
    background-position: 90% 100%;
  }
  100% {
    background-position: 100% 95%;
  }
}

.homepage-above-fold-container {
  height: 100vh;
  width: 100%;
  background-color: $primary-dark;

  .replacement-image {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-size: cover;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    animation: wave-animation 20s ease-out 1;
  
    @media (max-width: $large) {
      // background-position: right;
    }
  }

  .ember-background-video {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 0;

    .overlay {
      position: absolute;
      // opacity: .85;
      z-index: 1;
      width: 100%;
      height: 100vh;
      background: white;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3ffffff', endColorstr='#ffffff', GradientType=0);
      /* IE6-9 */
    }

    .text-carousel {
      z-index: 3;
      position: relative;
      overflow: hidden;

      &:after,
      &:before {
        content: '';
        display: table;
        clear: both;
      }

      .carousel-cotent {
        display: flex;
        padding-left: $global-margin*2.5;
        margin-top: 15%;
        transition: margin .6s ease;

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
          margin-top: 27% !important;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
          padding-left: 0px !important;
        }

        @media (max-width: $large) {
          padding-left: $global-margin*1.5;
          margin-top: 35%;
          margin-left: auto;
          margin-right: auto;
          width: 90%;
        }

        @media (max-width: $medium) {
          padding-left: 0;

        }

        .progress-container {
          width: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          opacity: .7;
        }

        .progress-bar {
          height: 99%;
          width: 2px;
          // border: $primary-dark 1px solid;
          // transform: rotate(90deg);
        }

        .loaded {
          height: 100%;
          width: 100%;
          background: $primary-dark;
          animation: 5s linear infinite loading;
          transform-origin: 0% 0%;
        }

        @keyframes loading {
          0% {
            transform: scaleY(0);
          }

          100% {
            transform: scaleY(100%);
          }
        }

        .hero-container {
          position: relative;

          .hero-header {
            opacity: 0;
            position: absolute;
            transition: opacity .4s ease;
            padding-left: $global-margin;

            &.active {
              display: block;
              opacity: 1;
              position: relative;
            }

            @media (max-width: $large) {
              padding-left: $global-margin*.5;
            }

            @media (max-width: $medium) {
              padding-left: 0px;
            }

            .typed-container {
              margin-bottom: $global-margin*.3;
              height: auto;
              width: 90%;

              @media (max-width: $large) and (min-width: $medium) {
                width: 95%;
              }

              @media (max-width: $medium) {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
              }

              h1 {
                margin: 0px;
                font-family: $sans-serif;
                font-size: 70px;

                @media (max-width: $large) and (min-width: $medium) {
                  font-size: 60px;
                }

                @media (max-width: $medium) {
                  font-size: 40px;
                }

                @media(max-width: 375px) {
                  font-size: 30px;
                }
              }
            }

            p {
              margin-bottom: 2.5rem;
              margin-top: 0px;
              font-family: $sans-serif;
              font-weight: 300;
              font-size: 22px;
              line-height: 39px;
              width: 65%;
              padding-left: 1rem;
              min-height: 120px;

              @media (max-width: $medium) {
                width: 90%;
                padding-left: 0;
                margin-left: auto;
                margin-right: auto;
              }

              @media(max-width: 375px) {
                font-size: 20px;
                line-height: 32px;
              }

              @media (min-width: $xlarge) {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
  }
}