// Colors
$color-white: #ffffff;
$color-black: #1F2021;
$color-primary: #B0B0B0;
$color-secondary: #B0B0B0;

$color-gray-100: #f7f7f7;
$color-gray-200: #efefef;
$color-gray-300: #e0e0e0;
$color-gray-400: #b0b0b0;
$color-gray-500: #657687;

$color-text-grey: $color-black;

$theme-max-width: 1366px;
$theme-colors: (
  'info': tomato,
  'primary': teal,
);

$link-color: $color-primary;

// Font Sizes
$font-size-base: 1.125rem;

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 2,
  5: $spacer * 3,
);

// Bootstrap overrides
$body-color: $color-text-grey;
$body-bg: #fdfdfc;


$small: 0;
$medium: 768px;
$large: 992px;
$xlarge: 1200px;
$xxlarge: 1400px;

$primary-sharp-dark: #202020;
$primary-dark: #343434;
$primary-light: #fdfdfc;
$alt-light: #f8f8f8;
$link-color-light: #3BA6DD;
$link-color-highlight: rgba(59,166,221, 0.8);
$off-black: rgb(89,89,89);
$logo-hover: #d0d0d0;
$border-light: #c2c2c2;

$global-margin: 5em;
$site-gutter: 25px;

$transition: ease all .4s;

body {
  background-color: $primary-light;
  margin: 0;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}


.fade-in {
  opacity: 1 !important;
}

.liquid-container {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
}

.liquid-animating .liquid-container {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600,700&display=swap');

$sans-serif:  'Source Sans Pro', sans-serif;

$font-family-base: 'Source Sans Pro', sans-serif;
body {
	p, a {
		font-size: 16px;
		font-family: $sans-serif;
	}
	a {
		color: $link-color-light;
		&:hover {
			color: $link-color-highlight;
		}
	}
	p {
		color: $primary-dark;
	}
	h1, h2, h3, h4, h5 {
		font-family: $sans-serif;
		color: $primary-dark;
	}
	h1 {
		font-size: 3.998rem;
    @media(max-width: $large) {
      font-size: 3.198rem;
    }
	}
	h2 {
		font-size: 2.827rem;
	}
	h3 {
		font-size: 1.999rem;
	}
	h4 {
		font-size: 1.414rem;
	}
	h5 {
		font-size: 1rem;
	}
	.dark-background {
		background-color: $primary-dark;
		h1,h2,h3, h4, h5, p {
			color: $primary-light;
		}
	}
	.light-background {
		background-color: $primary-light;
	}

}
