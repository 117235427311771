.ExpertiseCard {
    margin-bottom: 2rem;
  
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  
    .card-title {
      font-size: 1.5rem;
      font-weight: bold;
    }
  
    .card-text {
      margin-bottom: 1rem;
    }
  
    .btn {
      align-self: flex-start;
    }
  }