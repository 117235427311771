@use '../../../MainMenus/components/Logo/variables' as logo;

$layers: (
  'tiles': 3,
  'header': 2,
  'backgroundImage': 1,
);

$offset-xs: (logo.$height-xs + (logo.$padding * 2));
$offset: (logo.$height + (logo.$padding * 2));
$tiles-overlap: 3rem;
