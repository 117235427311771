.TileBlock__wrapper {
  // intentionally blank
}

.TileBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.TileBlock__header {
  text-align: left;
  align-self: baseline;
  display: flex;
  align-items: center;
  padding-left: 2.25rem;
  h3 {
    padding: 0;
  }
  
  &.clickable {
    cursor: pointer;
    &:hover {
      h3{
        color: $link-color-light;

      }
      svg {
        stroke: $link-color-light;
      }
    } 
  }
  @include media-breakpoint-down(md) {
    align-self: center;
    padding-left: 0;
  }
}

.TileBlock__arrow {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
  height: 24px;
  width: 24px;
  align-self: center;
}

.TileBlock__arrow--rotated {
  transform: rotate(180deg);
}

