.not-found-container {
    position: relative;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  
  .LongButton {
    &.not-found-button {
      padding-left: 0;
      margin: 0 auto;
      margin-top: 3rem;
    }
  }
  
  .not-found-row {
    height: 100%;
    margin: 0;
  
    .col {
      padding: 0;
    }
  }
  
  .video-background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1;
  }
  
  .not-found-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  
    h1 {
      font-size: 6rem;
      margin-bottom: 1rem;
      color: #fff;
    }
  
    p {
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
    }
  }
  