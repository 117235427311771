@use 'sass:math';

.ContactFormBlock__wrapper {
  // intentionally blank
}

.ContactFormBlock {

    width: (math.div(10, 12) * 100%);
    margin-right: auto;
    margin-left: auto;
  

  display: flex;
  flex-direction: column;
  gap: 3rem;
  .social-icons {
    svg {
      cursor: pointer;
      transition: ease all .4s;
      fill: #343434;
      height: 40px;
      width: auto;
      margin-right: 30px;
      display: inline;
      
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 3rem;
        
    }
  }
  .contact-icons {
    transition: ease all .4s;
    fill: #343434;
    height: 25px;
    width: auto;
    margin-right: 15px;
  }
  .contact-details {
    h2 {
      margin-bottom: 2rem;}
    p {width: 230px;
      display: block;
      margin-bottom: 25px;}
      a {
        transition: ease all .4s;
        cursor: pointer;
        text-decoration: none;
        font-weight: 300;
        color: #343434;
        position: relative;
        vertical-align: middle;
        &:hover {
          color: $link-color-light;
          svg {
            fill: $link-color-light;
          }
        }
      }
  }
}

.ContactFormBlock__header__wrapper {

  @include media-breakpoint-down(xxl) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3rem;
  }

  line-height: 1.2;
}

.ContactFormBlock__header {
  margin: 0; // override default <h*>
}

.ContactFormBlock__form {

  .InputWrapper {
    padding-bottom: 2rem;
  }
}
