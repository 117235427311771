.LogoBlock__wrapper {
  // intentionally blank
}

.LogoBlock {
  .LogoBlock__col {
    height: 125px;
    transition: height ease .2s;
    margin-bottom: 30px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: $large) {
      margin-bottom: 1rem;
    margin-top: 1rem;
    }

    a {
      // margin: 25px auto;
      transition: transform ease .2s, box-shadow ease .2s;
      width: auto;
      height: auto;
      // position: relative;
      display: flex;
      max-width: 60%;
      max-height: 60%;

      // max-height: 60px;
      &:hover {
        transform: scale(1.05) translateY(-5px);
      }
    }

    img {
      // position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      max-width: 60%;
      max-height: 60%;
      margin: auto;
      object-fit: contain;
    }

    @media (min-width: 992px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}