.image-list-wrapper {
  background-color: $primary-light;
  .image-list-container {
    width: 85%;
    margin: 0 auto;
    .col-lg-7 {
      padding-top: $global-margin*1.5;
      padding-bottom: $global-margin;
      img {
        height: auto;
        width: 70%;
        display: block;
        @media(max-width: $large) {
          margin: auto;
        }
      }
    }
    .col-lg-5 {
      ul {
        width: 80%;
        float: right;
        list-style: none;
        @media(max-width: $large) {
          width: 95%;
        }
        li {
          position: relative;
          font-family: $sans-serif;
          font-weight: 300;
          color: $primary-dark;
          letter-spacing: .7px;
          font-size: 1.325em;
          margin-left: 30px;
          margin-top: 50px;
          margin-bottom: 80px;
          @media(max-width: $medium) {
            font-size: 1.125em;
            margin-left: 50px;
          }
          .icon {
            top: -5px;
            left: -70px;
            position: absolute;;
            width: 35px;
            height: 35px;
            fill: $link-color-light;
            @media(max-width: $medium) {
              top: -3px;
              left: -60px;
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }
  }
}