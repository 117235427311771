.FeaturedProjectsBlock__wrapper {
  background-color: $body-bg;
}

.FeaturedProjectsBlock {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  .row {
    width: 100%;
  }
}

.projects-grid {
  .grid-item-wrapper {
    padding: 1.5rem 0;
  }
  .first-wrappers {
    &:nth-child(2) {
      padding-right: 1.5rem;
      @include media-breakpoint-down(md) {
        padding-right: 0;
      }

      .grid-item {
        height: 698px;
        @include media-breakpoint-down(md) {
          height: 325px;
        }
      }
    }
  }
  .stacked {
    padding: 0;
    padding-left: 1.5rem;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
    .row {
      margin: 0;
    }
  }


  .grid-item {
    -webkit-box-shadow: 4px 4px 15px 2px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 4px 4px 15px 2px rgba(0, 0, 0, 0.13);
    box-shadow: 4px 4px 15px 2px rgba(0, 0, 0, 0.13);
    transition: box-shadow ease 1s, transform ease 1s;
    transition: -moz-box-shadow ease 1s, -moz-transform ease 1s;
    transition: -webkit-box-shadow ease 1s, -webkit-transform ease 1s;
    position: relative;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    height: 325px;
    border-radius: 3px;
    .grid-item-overlay {
      opacity: .3;
    }

    .grid-item-inset {
      opacity: 1;
    }

    .svg-container-project {
      opacity: 1;
    }

    &:hover {
      -webkit-box-shadow: 10px 10px 20px -6px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 10px 10px 20px -6px rgba(0, 0, 0, 0.33);
      box-shadow: 10px 10px 20px -6px rgba(0, 0, 0, 0.33);
      transform: scale(1.05) translateY(-5px);
      z-index: 2;

      .grid-item-overlay {
        opacity: .6;
      }

      .grid-item-inset {
        opacity: 1;
      }

      .svg-container-project {
        opacity: 1;
      }
    }
  }

  .grid-item-overlay {
    z-index: 1;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: absolute;
    height: 100%;
    width: 100%;
    background: $primary-dark;
    opacity: 0;
    border-radius: 3px;
  }

  .grid-item-inset {
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    z-index: 2;
    position: absolute;
    border: 1px solid $primary-light;
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;
    margin: auto;
  }

  .svg-container-project {
    opacity: 0;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    z-index: 1000;
    height: 225px;
    width: 225px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-position: center;
    background-size: cover;

    .svg-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      svg {
        height: 100%;
        width: 100%;
        fill: $white !important;

        path {
          fill: $white !important;
        }
      }
    }
  }
}
