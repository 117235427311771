@use 'sass:map';
@use '../../variables'as page-blocks;
@use './variables'as expertise-block-landing;

.ExpertiseHeroLandingBlock {
  // height: 600px;
  background-position: center;
  background-size: cover;
  // height: calc(100vh - 650px);
  margin-top: 12rem;
  
  .container {
    height: 600px;

  }
  .ExpertiseHeroLandingBlock__row {
    height: 100%;
  }
  .ExpertiseHeroLandingBlock__overlay {
    height:100%;
    width: 100%;
    background-color: rgba(#343434, 0.25);
    position: absolute;
    top:0;
    left:0;
    z-index:0;
  }

  h1 {
    color: #fdfdfc;
    font-family: "Source Sans Pro", sans-serif;
    width: 70%;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3.998rem;
    font-weight: 600;
    position: relative;
    z-index:1;
  }

  p {
    font-weight: 500;
    color: #fdfdfc;
    width: 85%;
    font-size: 20px;
    line-height: 1.7em;
    position: relative;
    z-index:1;
  }
  .ExpertiseHeroLandingBlock__menu {
    padding:0;
    a {
      text-decoration: none !important;
    }
  }
  .ExpertiseHeroLandingBlock__selector {
    padding: 1.5rem 0;
    padding-left: 1rem;
    transition: all ease .6s;
    border-top:#343434 1px solid;
    &:first-child{
      border-bottom: none;
    }
    
    
    img {
    height: 30px;

    }

    &:hover {
      background-color: rgba(52,52,52,.85);
      h3 {color: white;}
      .arrow {
        span {border:solid white; border-width: 0 2px 2px 0;}
      }
      
    }
    .arrow {
      height: 40px;
      width: 40px;
      span {
        transition: border ease-in-out .4s;
        border: solid #343434;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 6px;
        margin-top: 10px;
      }
      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);

      }
    }
  }
  .ExpertiseHeroLandingBlock__menu_header {
    padding-top: 3rem;
    padding-left: 1rem;
  }

}
.ExpertiseHeroLandingBlock__text{
  padding: 3rem;
  position: relative;
  height: 100%;
  }