@use 'sass:map';
@use '../../../variables' as page-blocks;
@use './variables' as image-title-block;

.StudyImageBlock__wrapper {
  // intentionally blank
}

.StudyImageBlock {
  display: grid;
  position: relative;
  &::before, &::after {
    content: "";
    z-index: 1;
    left: 0;
    pointer-events: none;
    width: 100%;
    
  }
  &::after {
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(to bottom,rgba(255,255,255,0),#f8f8f8 100%);
    height: 100%;
    


  }
  &::before {
    position: absolute;
    top: 0;
    background-image: linear-gradient(to top,rgba(255,255,255,0),#fff 100%);
    height: 100%;

  }
}

.StudyImageBlock__header__wrapper,
.StudyImageBlock__backgroundImage {
  grid-area: 1 / 1 / 2 / 2;
}

.StudyImageBlock__header__wrapper {
  position: relative;
  z-index: map.get(image-title-block.$layers, 'header');

  min-height: 540px;
  padding: var(--bs-gutter-x, 0.75rem);

  &.black {
    color: $color-black;
  }

  &.white {
    color: $color-white;
  }

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 250px;
  }
}

.StudyImageBlock__header {
  margin: 0; // override default <h*>

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-only(sm) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.75rem;
  }

  line-height: 1;
}

.StudyImageBlock__backgroundImage {
  position: relative;
  z-index: map.get(image-title-block.$layers, 'backgroundImage');

  max-height: 640px;
}
