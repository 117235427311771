@use './variables' as logo;

.Logo__wrapper {
  display: inline-block;

  padding:0;
  padding-top:5px;
  &:hover {
    color: $color-black;
    svg {
      fill: $link-color-light;
      transition: fill 0.3s ease-in-out;
    }
  }

  &.areMainLinksVisible {
    color: $color-secondary;
  }
  .logo-container {
    z-index: 1;
    position: relative;
    height: 90%;
    width: 255px;
    margin-left: $site-gutter;
    @media(max-width: $medium) {
      width: 200px;
    }
    @media(max-width: 375px) {
      width: 175px;
    }
    svg {
      transition: fill 0.3s ease-in-out;
      fill: $primary-sharp-dark;
    }
  }
}
.menu__container{
  .logo-link {
    display: block;
    z-index: 100;
    position: absolute;
  }
}

.Logo {
  display: inline-block;
  width: auto;

  @include media-breakpoint-down(sm) {
    height: logo.$height-xs;
    margin-top:10px;
  }

  @include media-breakpoint-up(sm) {
    height: logo.$height;
  }

  fill: currentColor;
}
